@import "styles/variables";

.search-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D7D7D7;
  width: 100%;
  background: inherit;
}

.text {
  width: auto;
  margin-right: 5px;
  margin-left: 5px;

  h5 {
    font-weight: 700;
    margin-bottom: 5px;
  }
  h6 {
    font-weight: 400;
    color: #8A8A8A;
  }
}

.item-button {
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.95);
  }
  &:active {
    filter: brightness(0.9);
  }
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 82%;
}

.main-icon {
  margin-right: 10px;
}