.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
  padding-top: 15px;
  width: 100%;
}

.flexRow {
  display: flex;
}
.steps {
  font-size: 18px;
  flex-grow: 1;
}

.distance {
  padding-right: 5%;
}

.breadcrumbs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
}

.numberContainer {
  display: flex;
  margin-left: 10%;
}

.number {
  border-radius: 50%;
  font-size: 15px;
  height: 24px;
  width: 24px;
  text-align: center;
  align-content: center;
}

.border {
  border: 1px solid black;
}

.icon {
  height: 24px;
  width: 24px;
  color: blue;
  margin-top: 2px;
}

.line {
  border-width: 1px;
  border-style: solid;
  width: 30%;
  height: 1px;
  align-self: center;
}

.textContainer {
  font-size: 14px;
  display: flex;
  margin-left: 8%;
  gap: 22%;

  > div {
    text-align: center;
  }
}

.reset {
  margin-right: 5%;
  font-size: 14px;
  height: 40px;
  width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 8px;
  background-color: blue;
  color: white;
}

.reset:hover {
  background-color: darkblue;
}

