.mainContainer {
  position: relative;
}

.boxUpgrade {
  position: absolute;
  top: 150px;
  left: 50%;
  padding-left: 20px;
  padding-right: 20px;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999; 
}

.container {

  &.blurBackground {
    filter: blur(4px);
    pointer-events: none;
  }
}

.title {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #494949;

  > div {
    font-weight: 700;
    font-size: 18px;
  }
}

.description {
  font-size: 14px;
  text-align: justify;
}

.descriptionTitle {
  font-size: 15px;
  font-weight: 700;
}

.subheading {
  color: #494949;
  font-weight: 700;
  font-size: 15px;
}

a {
  font-size: 15px;
  color: blue;
  cursor: pointer;
}

a:hover {
  color: darkblue; 
  text-decoration: underline;
}