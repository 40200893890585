@import "styles/variables";

.container {
  width: 40%;
  min-height: 50px;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  position: absolute;
  width: 375px;
  top: 72px;
  left: calc(max(8%, 120px) + 20px);
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
  max-height: 90vh;
  overflow: hidden;
  color: #464646;
  overflow-y: auto;
}

.header {
  width: 100%;
  .tright {
    text-align: right;
  }
}

.header-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
  margin-left: 2px;
}

.close {
  @include opacity-change;
  margin-right: -12px;
  margin-top: -3px;
}


@media screen and (max-width: 1024px) {
  .container {
    width: 70%;
  }
}