@import "styles/variables";

.zoom {
  display: flex;
  background: white;
  padding: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;

  * { cursor: pointer; }
  svg { transition: all 0.3s; }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover {
    svg {
      filter: opacity(0.6);
    }
  }

  &:active {
    svg {
      filter: opacity(0.4);
    }
  }
}

.tilt {
  flex-direction: column;

  &:hover {
    svg {
      filter: opacity(1);
    }
  }

  &:active {
    svg {
      filter: opacity(1);
    }
  }

  svg {

    &:hover {
      filter: opacity(0.6);
    }
  
    &:active {
      filter: opacity(0.4);
    }
  }
  button {
    margin: 1px 0px;

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.controls {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 8;
  display: flex;
  flex-direction: column;
}

.group {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
}

.divider {
  height: 1px;
  width: 80%;
  margin-left: 10%;
  background: #E2E2E2;
}

.tools {
  background: #fff;
  color: #333;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 2px;
}

.compass {
  background: transparent;
  margin-top: 7px;
  margin-bottom: 8px;
  > div {
    display: none;
  }
  img {
    width: 46px;
    height: 46px;
  }
}

.tbutton {
  background: #fff;
  display: flex;
  justify-content: center;
  width: 46px;
  &:hover {
    img {
      background-color: #F5F5F5;
    }
  }
  > div {
    display: none;
  }
  img {
    padding: 8px;
    margin: 5px;
    border-radius: 7px;
    display: block;
  }
  svg {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.tbutton_sel {
  img {
    background-color: #F5F5F5;
  }
}

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464646;
  .overlayContent {
    background: #fff;
    width: 420px;
    max-width: 100%;
    border-radius: 8px;
    text-align: center;
    color: #333;
    h5 {
      font-weight: 700;
      font-size: 18px;
      padding: 0px 24px;
      padding-top: 24px;
    }
    p {
      padding: 0px 24px;
      font-size: 16px;
    }
  }
  .overlayButton {
    border-top: 1px solid #eee;
    padding: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #1553D3;
    cursor: pointer;
  }
}

@media screen and (max-width: 620px) {
  .groupTool {
    height: 0;
  }
  .controls {
    top: 100px;
  }
}
