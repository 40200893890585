@import "styles/variables";

.header {
  position: relative;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #1553D3;
  align-content: center;
}

.imageTop {
  width: 230px;
  height: 36px;
  object-fit: cover;
  margin-left: 20px;
  cursor: pointer;
}

.map-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.map-background-image {
  position: absolute;
  top: 100px;
  left: 0;
  width: 60vw;
  height: calc(100vh - 100px);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
}

.overlayContent {
  display: flex;
  flex-direction: column;
  padding: 64px;
  padding-bottom: 0;
  gap: 16px;
  align-items: center;
}

.overlayTextOne {
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
}

.overlayTextTwo {
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}

.overlayImage {
  width: 100%;
  height: 100%;
  max-width: 670px;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .nav {
    display: none;
  }
}

@media screen and (max-width: 1268px) {
  .overlayTextOne {
    font-size: 32px;
    line-height: 48px;
  }
  
  .overlayImage {
    width: 38%;
    height: 38%;
  }
}

@media screen and (max-width: 1550px) {
  .overlayTextOne {
    font-size: 32px;
    line-height: 48px;
  }
}

@media screen and (max-width: 2000px) {
  
  .overlayImage {
    width: 85%;
    height: 85%;
  }
}

@media screen and (min-width: 1550px) {
  .overlayContent {
    padding: 125px;
  }
}

