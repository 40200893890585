@import "styles/variables";

.link-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 40px 2vw;
  align-items: center;

  h1 {
    margin-top: 30px;
    color: rgba(0,0,0,0.8);
  }
}

.link-form {
  margin-top: 30px;
  width: 50vw;
}

.description {
  width: 50vw;
  text-align: center;
  margin: 0;
  margin-top: 15px;
}

.result {
  margin: 30px 0;

  input {
    width: 100%;
    padding: 15px 20px;
    font-size: 18px;
    margin-top: 20px;
  }
}

.qr {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .link-form, .description {
    width: 70vw;
  }
}