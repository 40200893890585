@import "styles/variables";

.delete {
  background-color: transparent;
  color: white;
  @include opacity-change;
}

.add {
  @include opacity-change;
  cursor: pointer;
  width: 20px;
  height: 28px;
}

.padd {
  display: flex;
  align-items: center;
  color: #1553D3;
  font-size: 14px;
  margin-left: auto;
}
