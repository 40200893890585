@import "styles/variables";

.container {
  position: absolute;
  top: 20px;
  left: 120px;
  z-index: 8;
  width: 280px;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
  font-family: Poppins;
}

.pane {
  background: white;
  width: 100%;
  position: absolute;
  top: 72px;
  max-width: 280px;
  left: 120px;
  z-index: 7;
  border-radius: 12px;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
  font-family: Poppins;
  overflow-y: auto;
  overflow-x: hidden;
  height: 0;
}

.pane-header {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .container, .pane {
    left: 82px;
  }
}

@media screen and (max-width: 620px) {
  .container, .pane {
    width: 200px;
    left: auto;
    right: 80px;
  }
}
