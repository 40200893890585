@import "styles/variables";

.snapper {
  position: absolute;
  top: 20px;
  left: calc(120px + 300px);
  z-index: 7;
  background-color: white;
  width: 220px;
  padding: 0px 10px;
  border-radius: 12px;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
  font-size: 16px;
}

.go-text {
  font-size: 16px;
}

.goButton {
  padding: 8px 1px !important;
  padding-left: 10px !important;
  border-left: 1px solid #E2E2E2 !important;
  border-radius: 0px !important;
  &:hover {
    background: transparent !important;
    opacity: 0.6;
  }
}

.goButton1 {
  padding: 0px !important;
  padding-left: 0px !important;
  border-radius: 0px !important;
  margin-top: 11px !important;
  margin-left: 11px !important;
  display: none !important;
  &:hover {
    background: transparent !important;
    opacity: 0.6;
  }
}

.mitem {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
}

@media screen and (max-width: 767px) {
  .snapper {
    left: calc(80px + 300px);
  }
}

@media screen and (max-width: 620px) {
  .snapper {
    left: auto;
    right: 18px;
    width: 46px;
    height: 46px;
    padding: 0px;
  }
  .goButton {
    display: none !important;
  }
  .goButton1 {
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    z-index: -1 !important;
  }
}
