@import "styles/variables";

.image-section {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  button {
    @include opacity-change;
  }
}

.pro {
  position: absolute;
  font-family: Poppins;
  font-weight: 700;
  font-size: 1.3rem;
  color: white;
  background-color: #0868E6;
  border-radius: 15px;
  padding: 10px 30px;
  border: 2px solid white;
  top: 15%;
  left: 70%;
  transform: translate(-50%, -50%);
}

.section {
  width: 80%;
  margin: 0 auto;
  font-size: 0.9rem;
  padding: 15px 0px;
  border-bottom: 1px solid #ACACAC;

  &:nth-child(3) {
    border-top: 1px solid #ACACAC;
  }
  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 30px;
  }
}

.title {
  font-weight: 700;
  margin-bottom: 10px;
}

.label {
  padding: 10px 20px;
}

.input {
  background: white;
  border: none;
  border-radius: 6px;
  margin: 0 20px;
  width: calc(100% - 20px);
  font-size: 0.9rem;
  padding: 8px;
  margin-bottom: 5px;
}

.link {
  @include opacity-change;
  text-decoration: underline;
  font-size: 0.9rem;
  padding: 5px 20px;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}

.linkimage {
  display: flex;
  img {
    margin-right: 5px;
  }
}

.static-text {
  font-size: 0.9rem;
  font-weight: 700;
  padding-left: 40px;
  margin-bottom: 5px;
}

.modal {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_cont {
  position: relative;
  padding: 30px;
  padding-top: 40px;
  width: 500px;
  height: calc(100vh - 60px);
  max-width: 90%;
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.20), 0px 7px 10px 0px rgba(0, 0, 0, 0.14), 0px 2px 16px 0px rgba(0, 0, 0, 0.12);
  div {
    font-size: 14px;
    color: #333;
  }
  &.modal_cont_confirm {
    height: auto;
    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .button_cancel,
    .button_upgrade {
      padding: 8px;
      text-align: center;
      width: 110px;
      border-radius: 24px;
      font-weight: 700;
      background: #e2e2e2;
      margin: 0px 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .button_upgrade {
      background: #1553D3;
      color: #fff;
    }
  }
}
.modal_title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.card_cont {
  overflow-y: auto;
  max-height: calc(100% - 100px);
  padding: 8px;
  margin-top: 10px;
  margin-left: -8px;
  width: calc(100% + 16px);
  max-width: calc(100% + 16px);
}
.card {
  padding: 20px;
  margin-bottom: 12px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
  img {
    margin-bottom: 5px;
  }
}
.discount {
  font-weight: 700;
  margin: 12px 0px;
}
.discount_btn {
  background: #e2e2e2;
  border-radius: 24px;
  font-weight: 700;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  display: block;
  color: #333 !important;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}
.model_close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  span {
    display: none;
  }
}