@import "styles/variables";

.container {
  background-color: white;
  width: 40vw;
  padding: 20px;
  border-radius: 24px;
  position: relative;
  
  * {
    font-family: Poppins;
  }
}

.close {
  @include opacity-change;
  position: absolute;
  right: 13px;
  top: 13px;
  outline: 0;
  background: transparent;
  z-index: 20;
}

@media screen and (max-width: 768px) {
  .container {
    width: 90vw;
  }
}