@import "styles/variables";

.popup {
  position: relative;
  background: white;
  width: 355px;
  min-height: 50px;
  border-radius: 6px;
  color: #464646;
}

.container {
  width: 355px;
}

.type {
  display: flex;
  align-items: center;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 8px;
  margin-right: 5px;
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.back {
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 5px;
  cursor: pointer;
  img {
    margin-right: 10px;
  }
}
.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.tabNetwork {
  font-weight: 500;
  color: #777;
}
.tabsWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #E2E2E2;
}
.tabs {
  display: flex;
  padding-top: 20px;
  .tab {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    border-bottom: 3px solid transparent;
    color: #777;
    &.sel {
      color: #333;
      border-color: #1553D3;
    }
    .tabval {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
      }
    }
  }
}
.chartCont {
  padding: 10px 5px;
}

.tbuttongroup {
  background: #F5F5F5;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
}
.tbutton, .tbutton_sel {
  border: 0px !important;
  background: #F5F5F5;
  padding: 3px 15px !important;
  font-size: 14px !important;
  font-family: Poppins;
  text-transform: none !important;
  color: #555;
  font-weight: 500;
  width: 25%;
}
.tbutton_sel {
  background: #fff !important;
  border-radius: 8px !important;
  box-shadow: 1px 1px 7px 0px #0000001F;
  color: #333 !important;
}
.chartpos {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 15px;
  img {
    margin-right: 5px;
  }
}
.drawCont {
  position: relative;
  cursor: crosshair;
  .line {
    position: absolute;
    border-left: 1px dashed #1553d3;
    height: 75%;
    z-index: 2;
    left: 342px;
    top: 9px;
    opacity: 0.6;
  }
}

.text {

  h5 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.3;
    margin-top: 0px;
  }
  h6 {
    font-weight: 500;
    font-size: 14px;
  }
  p {
    color: grey;
    font-size: 0.7rem;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.header {
  position: relative;
  padding-bottom: 10px;
  .close {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
  .text {
    padding: 20px 10px 0;
    .type {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }
    h4 {
      font-weight: 700;
      font-size: 22px;
      line-height: 1.3;
    }
    h5 {
      font-weight: 500;
      font-size: 14px;
      margin-top: 5px;
    }
    h6 {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.gdata {
  border-top: 1px solid #E2E2E2;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
}
.viewcard {
  * {
    font-family: sans-serif;
  }
  box-shadow: 0px 1px 6px 0px #00000024;
  border-radius: 16px;
  padding: 10px 12px;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  > img {
    margin-right: 10px;
  }
  &:hover {
    background: #F5F5F5;
  }
  .gtitle {
    font-weight: 600;
  }
  .normal {
    font-size: 11px;
    font-weight: 400;
    color: #464646;
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }
}
.viewdata {
  color: #1553D3;
  font-weight: 600;
  margin-left: auto;
}

.url {
  color: #1553D3 !important;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  img {
    margin-left: 5px;
  }
}
.surl {
  padding-left: 5px;
}
.distances {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 30px;
  padding: 6px;
  border-top: 1px solid #E2E2E2;
  font-size: 13px;
  .distanceInner {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-size: 13px;
      color: #555;
      margin: 0;
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.button {
  width: 45%;
  margin: 0px 10px;
  color: white;
  background-color: #0B68E5;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.8rem;
  @include opacity-change;
  img {
    width: 48px;
    height: 48px;
  }
}
.scontent {
  overflow-y: auto;
  max-height: calc(100vh - 400px);
}