@import "styles/variables";

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.button {
  margin: 0px 10px;
  margin-top: 5px;
  color: white;
  border-radius: 24px;
  background-color: #1553D3;
  font-weight: 600;
  padding: 10px 20px 10px 20px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:first-of-type {
    margin-left: 0px;
  }

  &:last-of-type {
    margin-right: 0px;
  }

  &:disabled {
    cursor: default;
    background-color: #8A9ABC;
  }
}

.icon {
  margin-right: 5px;
}

.icon-el {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}