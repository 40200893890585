.mainContainer {
  position: relative;
}

.boxUpgrade {
  position: absolute;
  top: 150px;
  left: 50%;
  padding-left: 20px;
  padding-right: 20px;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999; 
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &.blurBackground {
    filter: blur(4px);
    pointer-events: none;
  }
}

.titleContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.title {
  font-size: 19px;
  font-weight: 600;
  color: #494949;
}

.description {
  font-size: 13px;
  text-align: justify;
}

.characteristicContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    gap: 10px;
  }
}

.characteristicImage {
  margin-top: auto;
  margin-bottom: auto;
}

.characteristicDetail {
  text-align: justify;
  font-size: 13px;
}