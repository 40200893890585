@import "styles/variables";

.container {
  flex-grow: 1;
  width: 100%;
  background-color: white;
}

.appbar {
  background-color: white !important;
  box-shadow: none !important;
}

.root {
  font-size: 16px !important;
  min-width: fit-content !important;
}