@import "styles/variables";

.container {
  width: 375px;
  position: absolute;
  top: 72px;
  left: calc(98px + 20px);
  z-index: 8;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  * {
    font-family: Poppins;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.selector {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0px;
}

.header-text {
  margin-left: 10px;
  font-family: Poppins;

  h5 {
    font-size: 1.1rem;
    font-weight: 600;
  }

  h6 {
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 3px;
  }
}

.heading {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.text {
  margin: 10px 0px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;

  button {
    flex-grow: 1;
  }
}

.submit-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;

  * {
    font-family: Raleway;
  }

  button {
    @include opacity-change;
  }
}

.disabled {
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.5;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    // width: 60%;
    left: calc(98px + 20px);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: 70%;
    left: 50px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 375px;
    left: 20px;
  }
}