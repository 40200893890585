@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-ExtraBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-Italic.ttf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-ExtraLight.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}