@import "styles/variables";

.title {
  margin: 10px auto;
  font-size: 1.4rem;
}

.group {
  width: 70%;
  margin: 20px 0px;
}

.label {
  margin-bottom: 5px;
}

.button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0px;
}

.error {
  font-size: 0.8rem;
  color: red;
}