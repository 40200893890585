@import "styles/variables";

.main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 28px;
}

.snapper {
  position: absolute;
  top: 20px;
  right: 80px;
  z-index: 7;
  background-color: white;
  min-width: 240px;
  padding: 0px 10px;
  border-radius: 6px;
  padding-bottom: 10px;
}

.title {
  font-size: 32px;
  color: #333;
  font-weight: 700;
  line-height: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5px;
}

.text {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  text-align: left;
}
  
.go-text {
  font-size: 1rem;
}

@media screen and (max-width: 850px) {
  .snapper {
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 500px) {
  .snapper {
    display: none;
  }
}
