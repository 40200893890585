@import "styles/variables";

.popup {
  position: relative;
  background: white;
  width: 355px;
  min-height: 50px;
  padding: 5px;
  border-radius: 6px;
  color: #464646;
  padding-top: 12px;
}

.container {
  width: 355px;
}

.type {
  display: flex;
  align-items: center;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 8px;
  margin-right: 8px;
  margin-top: 2px;
  border: 1px solid #333;
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.text {
  width: 85%;

  h5 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.3;
    margin-top: 0px;
  }
  h6 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  p {
    color: grey;
    font-size: 0.7rem;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.header {
  position: relative;
  padding: 10px;
  .close {
    position: absolute;
    cursor: pointer;
  }
}

.distances {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 30px;
  padding: 6px;
  border-top: 1px solid #E2E2E2;
  .distanceInner {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      color: grey;
      margin: 0;
    }
  }
}
.description {
  padding: 15px 6px;
}
.landTop {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  .landIcon {
    width: 22px;
    height: 22px;
    background-color: #0B68E5;
    border-radius: 4px;
    border: 2px solid #555;
    margin-right: 5px;
  }
  .landText {
    font-size: 16px;
    color: #464646;
    font-weight: 700;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.button {
  width: 45%;
  margin: 0px 10px;
  color: white;
  background-color: #0B68E5;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.8rem;
  @include opacity-change;
  img {
    width: 48px;
    height: 48px;
  }
}
.scontent {
  overflow-y: auto;
  max-height: calc(100vh - 400px);
}