@import "styles/variables";

.container {
  background-color: white;
  width: 100%;
  padding: 2.5%;
  border-radius: 10px;
  position: relative;
  
  div {
    font-family: Poppins;
    font-weight: 400;
    font-size: 1.2rem;
  }
}

.close {
  @include opacity-change;
  position: absolute;
  right: 20px;
  top: 20px;
  outline: 0;
  background: transparent;
}

.buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.secondary {
  width: 50%;
  border-radius: 6px;
  background-color: white;
  border: 2px solid $primary;
  color: $primary;
  font-weight: 600;
}

.primary {
  width: 50%;
  border-radius: 6px;
  background-color: $primary;
  font-weight: 600;
}

.bright {
  background-color: rgb(36, 91, 188);
  div {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1rem;
  }
}

.logo {
  margin: 0 auto 15px auto;
  display: block;
}

.helper {
  font-size: 0.9rem !important;
  color: rgb(100, 100, 100);
  margin: 15px;
}

.cancel-desc {
  font-size: 0.9rem;
  margin: 10px 0;
  width: 100%;
}

.cancel-button {
  padding: 8px 40px;
  border-radius: 15px;
  background-color: rgb(226, 23, 23);
  font-weight: 600;
  color: white;
  font-size: 1rem;
  margin-top: 30px;
  @include opacity-change;

  &::disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.proContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  padding-bottom: 10px;
}

.proTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.proText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.proCheck {
  margin-top: 4px;
}

.proDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.proLink {
  color: #1553D3;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.basicTitle {
  color: #1553D3;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin: 0;
}