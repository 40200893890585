@import "styles/variables";

.container {
  width: 40%;
  min-height: 50px;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 90vh;
  overflow-y: auto;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.title {
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.1rem;
  margin-left: 5px;
}

.close {
  @include opacity-change;
}