.section {
  display: flex;
  align-items: center;
  padding: 10px 30px 0px 0px;
  gap: 10px;
}

.title {
  flex-grow: 1;
  font-size: 19px;
  color: #494949;
  font-weight: 700;
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0B68E5;
  color: white;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.iconButton:hover {
  opacity: 0.8;
}

.icon {
  height: 24px !important;
  width: 24px !important;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}