@import "styles/variables";

.popup {
  position: relative;
  background: white;
  width: 350px;
  min-height: 50px;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.text {
  width: 75%;

  h5 {
    font-weight: 700;
    font-size: 0.8rem;
  }
  h6 {
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 5px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.button {
  width: 45%;
  margin: 0px 10px;
  color: white;
  background-color: #0B68E5;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.8rem;
  @include opacity-change;
  img {
    width: 48px;
    height: 48px;
  }
}