@import "styles/variables";

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px 20px;
  padding: 0px;
  padding-top: 16px;
  border-top: 1px solid #E2E2E2;
    
  .button {
    background-color: transparent;
    color: #333333;
    font-weight: 600;
    font-size: 11px;
    min-width: 80px;
    @include opacity-change;
    img {
      width: 48px;
      height: 48px;
    }
    &.disabled {
      opacity: 0.6;
    }
  }

}

.orangeButton {
  background-color: #FF7F00;
}

.icon {
  margin-right: 5px;
}

.icon-el {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}