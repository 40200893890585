.mainContainer {
  position: relative;
}

.boxUpgrade {
  position: absolute;
  top: 150px;
  left: 50%;
  padding-left: 20px;
  padding-right: 20px;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999; 
}

.container {
  position: relative;

  &.blurBackground {
    filter: blur(4px);
    pointer-events: none;
  }
}

.header {
  position: sticky;
  top: -20px;
  z-index: 1000;
  border-bottom: 1px solid #dddddd;
  background-color: gray;
  padding: 10px;
  color: white;
  font-size: 14px;
  display: flex;
  width: 100%;
  align-items: center;
}

.watershedButton {
  margin-left: auto;
  padding: 4px 6px 4px 6px;
  font-size: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gageContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease;
}

.gageContainer:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.titleContainer {
  display: flex;
  gap: 10px;
}

.title {
  font-size: 16px;
  color: #494949;
  font-weight: 700;
}

.subtitle {
  font-size: 12px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  > div {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 4px;
    padding-bottom: 0;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
}

.cardTitle {
  font-size: 14px;
  color: #494949;
  font-weight: 700;
}

.cardValueContainer {
  display: flex;
}

.cardIcon {
  color: #494949;
}

.cardValue {
  margin-left: auto;
  font-size: 0.75rem;

  > span {
    font-size: 16px;
    font-weight: 700;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.button {
  flex-grow: 1;
}

.emptyMessage {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 10px;
  color: #494949;
  font-weight: 500;
  font-size: 15px;
}