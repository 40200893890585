@import "styles/variables";

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.top-selector {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
}

.top-selector > div {
  margin: 0px 10px;
}