@import "styles/variables";

.primary {
  width: 100%;
  border-radius: 24px;
  background-color: #1553D3;
  font-weight: 600;
  padding: 10px 20px 10px 20px;
  &:disabled {
    background-color: #8A9ABC;
  }
}

.bright {
  background-color: #1553D3;
  div {
    font-family: Poppins;
    font-weight: 700;
    font-size: 16px;
  }
}

.logo {
  margin: 0 auto 15px auto;
  display: block;
}

.paymentModal {
  height: 80vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    font-size: 0.95rem;
  }

  * { font-family: Poppins; }

  h4 {
    font-size: 1rem;
  }
}

.payment {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    font-size: 0.95rem;
  }

  * { font-family: Poppins; }

  h4 {
    font-size: 1rem;
  }
}

.card {
  display: flex;
  width: 100%;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  margin-top: 0;
  max-height: 50px;
  border: 2px solid #E2E2E2,
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: center;

  h4 {
    font-size: 16px;
    color: #1553D3;
  }
}

.payment-details {
  display: flex;
  flex-direction: column;
  gap: 0px;
  text-align: center;
  background-color: white;
  width: 60%;
  max-width: 200px;
  border-radius: 16px;
  box-shadow: 0px 2px 5px 0px #00000024;
  padding-top: 0px;
  padding-right: 18px;
  padding-bottom: 18px;
  padding-left: 18px;
  margin-bottom: 20px;

  p {
    font-size: 14px;
    margin: 0;
  }

  p:nth-of-type(1) {
    font-weight: 700;
  }

  @media (max-width: 475px) {
    width: 75%;
  }
  @media (max-width: 380px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    min-width: 200px;
  }
}

.payment-circle {
  width: 20px;
  height: 20px;
  background-color: #1553D3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 22px;

  div {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
  }
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  h3 {
    color: #1553D3;
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
  }

  p:nth-of-type(1) {
    text-align: start;
    padding-right: 3px;
  }
  p:nth-of-type(2) {
    text-align: center;
    padding-right: 3px;
    padding-left: 3px;
  }
  p:nth-of-type(3) {
    text-align: end;
    padding-left: 3px;
  }

  .line {
    width: 38%;
    height: 2px;
    background-color: #1553D3;
    position: relative;

    @media (min-width: 600px) and (max-width: 1000px) {
      width: 43%;
    }
  }

  @media (max-width: 600px) {
    display: none;;
  }
}

.cardholder-name {
  width: 70%;
}

.helper {
  font-size: 12px !important;
  color: #464646;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

.cancel-desc {
  font-size: 0.9rem;
  margin: 10px 0;
  width: 100%;
}

.paymentHeaderLabel {
  font-size: 14px;
  text-align: left;
}

.paymentLabelContainer{
  margin-top: 24px;
  width: 100%;
}

.coupon {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.membership {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.membership-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
  margin-left: -10px;
}

.update {
  margin-bottom: 20px;
}

.originalPrice {
  font-size: 16px;
  font-weight: 700;
  color: #1553D3;
}

.oldPrice {
  text-decoration: line-through;
  font-size: 16px;
  color: #1553D3;
}

.newPrice {
  font-size: 1rem;
  font-weight: bold;
  color: $primary;
}

.monthlyPrice {
  font-size: 0.9rem;
}

.paymentTitle {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 25px;

  h3 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
  }
}

.paymentPath {
  width: 90%;
}

.linkButton {
  color: #1553D3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}