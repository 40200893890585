@import "styles/variables";

.icon-button {
  @include opacity-change;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  margin-right: 5px;
  padding: 10px;

  div {
    margin-top: 5px;
    word-break: break-word;
  }
}

.selected {
  border: 1px solid rgba(0,0,0,0.6);
  border-radius: 5px;
}

.distances {
  display: flex;  
  width: calc(100% + 40px);
  max-width: calc(100% + 40px);
  margin-left: -20px;
  align-items: center;
  gap: 20px;
  padding: 8px 20px;
  border-top: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  .distanceInner {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-size: 13px;
      color: #555555;
      margin: 0;
    }
  }
}

.divider {
  height: 1px;
  margin: 5px -20px;
  width: calc(100% + 40px);
  max-width: calc(100% + 40px);
  margin-bottom: 10px;
  background: #ddd;
}
.address {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 8px;
}
.flex {
  display: flex;
  width: 100%;
  .selector:nth-child(1) {
    width: 27%;
  }
  .selector:nth-child(2) {
    width: 73%;
    margin-left: 10px;
  }
}
.selectColor {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;
}
.selectType {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 28px;
    margin-right: 5px;
  }
}
.selector {
  input {
    display: none;
  }
}

.scontent {
  overflow-y: auto;
  height: 420px;
  max-height: calc(100vh - 400px);
  width: 100%;
  overflow-x: hidden;
}