@import "variables";

* {
  box-sizing: border-box; 
  font-family: Poppins, Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

input:focus, button:focus, div:focus {
  outline: 0;
}

button, a {
  border: 0;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: default !important;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s all;

  &:hover {
    color: $primary;
  }
  &:active {
    opacity: 0.7;
  }
}

p {
  margin: 1rem 0;
}

.transparent {
  outline: 0;
  background: none;
}

.popup {
  border-radius: 10px;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

html {
  @include fluid-type($min-width, $container-width, $min-font, $max-font);
}

iframe {
  width: 100% !important;
}

.smartbanner {
  z-index: 100;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.popup .mapboxgl-popup-content {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.popup.mapboxgl-popup {
  transform: none !important;
  top: 72px;
  left: calc(98px + 20px);
}

.popup.mapboxgl-popup .mapboxgl-popup-tip {
  display: none;
}

.ReactGridGallery > div {
  flex-wrap: nowrap !important;
  overflow: hidden;
  overflow-x: auto;
}

ul.ril__toolbarRightSide > li:first-child > button {
  position: relative;
  top: 6px;
}

@media screen and (max-width: 767px) {
  .popup.mapboxgl-popup {
    max-width: 375px;
    left: 20px;
  }
}