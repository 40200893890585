@import "styles/variables";

.gridContainer {
  display: grid;
  grid-template-columns: 1fr auto;
}

.rightColumn {
  justify-self: end;
  width: 130px;
}

.upgrade {
  border: 2px solid #494949;
  color: #494949;
  width: fit-content;
  padding: 5px 9px 5px 9px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
}

.primary {
  font-weight: 600;
  margin-bottom: 5px;
}

.secondary {
  color: #494949;
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.elev {
  display: flex;
  gap: 4px;
  color: #494949;
}

.elevIcon {
  margin-top: 2px;
  height: 20px !important;
  width: 20px !important;
}

.elevValue {
  font-size: 16px;
}

.close {
  text-align: right;
  cursor: pointer;
  margin-top: -10px;
  img {
    margin-right: -8px;
  }
}

.divider {
  height: 1px;
  margin: 15px 5px;
  width: 100%;
  background-color: #7E7E7E;
}

.button {
  background-color: white;
  color: #0B68E5;
  border: 1px solid darkgray;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 20px;
  margin-right: 6px;
  margin-left: 6px;
}

.blueButton {
  margin-left: 6px;
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 20px;
  font-weight: 500;
}

.classes {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: auto;
  max-width: fit-content;
  border-radius: 8px;
  padding: 5px;

  .classContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    padding: 8px;
  }

  .classText {
    font-size: 16px;
    font-weight: 700;
    color: #494949;
    opacity: 0.9;
  }

  .class {
    border-radius: 8px;
    border: 2px solid;
    padding: 5px 20px;
    font-size: 0.9rem;
    font-weight: 700;
    color: black;
    flex: 1 1 0;
    text-align: center;
  }
}

.popover {
  z-index: 9999;
  pointer-events: none;
}

.class-description {
  width: 500px;
  padding: 15px;

  h4 {
    font-size: 1rem;
  }
  p {
    font-size: 14px;
    text-align: justify;
    padding-right: 5px;
    line-height: 20px;
  }
}

.reg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;

  .reg-box {
    border-radius: 10px;
    padding: 10px 15px;
    color: black;
    text-align: center;
    font-weight: 600;
    border: 2px solid black;
    min-width: 150px;
    max-width: 150px;
  }

  .reg-description {
    font-size: 0.8rem;
    margin-left: 15px;
  }
}

.stream-regs {
  h4 {
    font-size: 0.9rem;
    margin: 20px 0 10px 0;
  }
  p {
    margin: 10px 0;
    font-size: 0.8rem;
    white-space: pre-wrap;
  }
}

@media screen and (max-width: $responsive-size) {
  .label-container {
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
  }
}