@import "styles/variables";

.image-square {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.responsive {
  width: 100%;
  height: auto;
}

.full-height {
  height: 100%;
}