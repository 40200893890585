@import "styles/variables";

.confirm-button {
  padding: 8px 40px;
  border-radius: 15px;
  background-color: rgb(23, 133, 23);
  font-weight: 600;
  color: white;
  font-size: 1rem;
  margin: 0 auto;
  margin-top: 30px;
  @include opacity-change;

  &::disabled {
    opacity: 0.5;
    cursor: default;
  }
}