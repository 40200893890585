@import "styles/variables";

.container {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  
  img {
    margin: 0 auto 10px auto;
  }
  p {
    text-align: center;
  }
}