.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.popup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease;
}

.popup:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.content {
  display: flex;
  gap: 10px;
}

.text {
  h5 {
    font-weight: 700;
    font-size: 15px;
  }
  h6 {
    font-weight: 400;
    font-size: 13px;
    margin-top: 5px;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.button {
  flex-grow: 1;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.emptyMessage {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 10px;
  color: #494949;
  font-weight: 500;
}