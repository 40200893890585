@import "styles/variables";

.color-button {
  @include opacity-change;
  width: 70px;
  height: 70px;
  border: 2px solid rgb(85, 85, 85);
  border-radius: 100px;
}

.select-button {
  @include opacity-change;
  border-radius: 6px;
  padding: 10px;
}

.active {
  border: 2px solid rgb(85, 85, 85);
}

.inactive-container {
  margin-right: 5px;
  padding: 5px;
}

.active-container {
  border: 2px solid rgb(85, 85, 85);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  padding: 5px;
}