.upgradeBox {
  display: flex;
  flex-direction: column;
  width: max-content;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.row1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

.row2 {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
}

.proBox {
  background-color: blue;
  padding: 10px;
  text-align: center;
  align-items: center;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  height: 45px;
  min-width: 60px;
}

.description {
  font-size: 16px;
}

.row3 {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  
  > button {
    font-size: 20px;
    color: blue;
    background-color: transparent;
  }

  > button:hover {
    text-decoration: none;
    color: darkblue;
  }
}
