@import "styles/variables";

.container {
  background-color: white;
  position: absolute;
  right: 0;
  top: 100px;
  height: calc(100vh - 100px);
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  z-index: 10;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
  overflow-y: auto;
}

.login-logo-image {
  cursor: pointer;
}

.title {
  font-size: 2rem;
  color: black;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: 44px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.wrapper {
  padding-bottom: 24px;
  width: 100%;
}

.checkbox {
  margin-left: -10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.options {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bottomLink {
  font-size: 12px;
  color: #1553D3;
  text-decoration: none;
}

.divider-vertical {
  height: 25px;
  width: 1px;
  background-color: #ABABAB;
  margin: 0 10px;
}

.bottom-section {
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.divider-horizontal {
  height: 1px;
  width: 100%;
  background-color: #ABABAB;
}

.bottomText {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container-centered {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-top: -20px;
}

.horizontal-text {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  span {
    font-size: 12px;
    margin: 0;
  }
  p {
    font-size: 16px;
    margin: 0;
  }
  button {
    transition: all 0.3s;
  }
  button:hover {
    opacity: 0.7;
  }
}

.password-box {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info {
  color: #2A8B61;
  font-size: 14px;
  text-align: center;
}

.error {
  color: #DB0000;
  font-size: 14px;
  text-align: center;
}

.payment-section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.status-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
}

.status {
  width: 100%;
  flex: 1;
  text-align: center;
  margin-right: 8px;
  padding-top: 5px;
  font-weight: 700;
  outline: 0;
  background: 0;
  font-size: 16px;
  cursor: default;
}

.active {
  color:#1553D3;
  border-top: 4px solid #1553D3;
}

.inactive {
  color: #bdbdbd;
  border-top: 4px solid #bdbdbd;
}

.mobile-logo {
  display: none;
  width: 60%;
  margin: 20px auto;
  margin-bottom: 0;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-top: 10px;
    border-radius: 10px;
  }
  h4 {
    margin: 0;
    margin-top: 20px;
  }
  h4, p {
    color: rgba(0,0,0,0.8);
  }
}

.membership-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 13%;
  margin-right: auto;
  margin-bottom: 15px;
}

@media screen and (max-width: 1000px) {
  .options {
    width: 100%;
  }
  .container {
    width: 100%;
    height: auto;
    min-height: 100vh;
  }
  .wrapper {
    margin-bottom: 30px;
  }
  .mobile-logo {
    display: flex;
  }
  .container {
    height: 100vh;
    top: 0;
  }
}