.content-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%; 
  margin: 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px 0px;

  &:first-of-type {
    margin-top: 40px;
  }
}

.content-title {
  margin-left: 2px;
  font-weight: 700;
  font-size: 0.95rem;
}

.row { 
  display: flex; 
  align-items: center;
}

.content-divider {
  margin: 0 auto;
  margin-top: 5px;
  width: 90%;
  background: black;
  height: 1px;
}

.triangle {
  margin-top: 3px;
}

.content-drawer {
  overflow: hidden;
  width: 90%; 
  margin: 10px auto 25px auto;
  height: 0px;
}