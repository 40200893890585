@import "styles/variables";

.container {
  width: 375px;
  position: absolute;
  top: 72px;
  left: calc(98px + 20px);
  z-index: 8;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 120px);
  overflow: hidden;
  color: #464646;

  * {
    font-family: Poppins;
  }
}

.header {
  position: relative;
  padding: 10px;
  .close {
    position: absolute;
    right: 12px;
    cursor: pointer;
  }
  .text {
    padding: 20px 10px 0;
    .type {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
      svg {
        width: 16px;
        height: 24px;
      }
    }
    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.3;
      margin-top: 5px;
    }
    h5 {
      font-weight: 500;
      font-size: 14px;
      margin-top: 5px;
    }
    h6 {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.distances {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  padding: 8px 20px;
  border-top: 1px solid #E2E2E2;
  .distanceInner {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-size: 13px;
      color: #555555;
      margin: 0;
    }
  }
}

.body {
  padding: 8px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  overflow-wrap: break-word;

  p {
    font-size: 14px;
    color: #333333;
    margin: 0;
  }
  .ext-link {
    display: block;
    color: #1553D3;
    overflow: hidden; 
    font-size: 14px;
    margin-top: 10px;
  }
  .action-link {
    display: block;
    color: #333333;
    font-size: 12px;
    font-weight: 600;
    padding: 12px 16px;
    background-color: #EBEBEB;
    border-radius: 20px;
    margin: 10px 0;
    width: fit-content;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px 20px;
  border-top: 1px solid #E2E2E2;
    
  .button {
    background-color: transparent;
    color: #333333;
    font-weight: 600;
    font-size: 11px;
    min-width: 80px;
    @include opacity-change;
    img {
      width: 48px;
      height: 48px;
    }
  }

}

.flex {
  display: flex;
  width: 100%;
  .selector:nth-child(1) {
    width: 27%;
  }
  .selector:nth-child(2) {
    width: 73%;
    margin-left: 10px;
  }
}
.selectColor {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
.selectType {
  display: flex;
  align-items: center;
  font-size: 14px;
  img {
    width: 28px;
    margin-right: 5px;
  }
}
.selector {
  input {
    display: none;
  }
}

.divider {
  height: 1px;
  margin: 5px -20px;
  width: calc(100% + 40px);
  max-width: calc(100% + 40px);
  margin-bottom: 10px;
  background: #ddd;
}

.deleteConfirm {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .deleteContent {
    background: #fff;
    width: 360px;
    border-radius: 8px;
    text-align: center;
    color: #333;
    h5 {
      font-weight: 700;
      font-size: 16px;
      padding: 0px 24px;
      padding-top: 30px;
    }
    p {
      padding: 0px 24px;
      font-size: 16px;
    }
  }
}
.deleteButtons {
  display: flex;
  width: 100%;
  border-top: 1px solid #ddd;
  .deleteButton {
    text-align: center;
    width: 100%;
    padding: 18px 0px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:nth-child(2) {
      border-left: 1px solid #ddd;
      color: #FF3300;
    }
  }
}

.scontent {
  overflow-y: auto;
  height: 420px;
  max-height: calc(100vh - 400px);
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 375px;
    left: 20px;
  }
}