@import "styles/variables";

.file-upload-section {
  width: 40%;
  min-height: 50px;
  background-color: #F2F2F2;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 13;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  p {
    font-weight: 400;
    font-size: 1rem;
  }
}

.dropped-pin-icon {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;

  h4 {
    margin-left: 10px;
    font-size: 1.2rem;
    font-weight: 700;
    color: rgba(0,0,0,0.8);
  }
}

.upload {
  @include opacity-change;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: $primary;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  margin: 10px auto !important;
  align-self: center;

  &:disabled {
    opacity: 0.5;
  }
}

.title {
  margin: 10px auto;
  font-size: 1.4rem;
  text-align: center;
}

.confirmation {
  margin: 20px auto;
  font-size: 1rem;
  text-align: center;
}

.uploadContent {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 auto;

  .uploadBox {
    border: 3px dashed #ccc;
    padding: 10px !important;
  }

  .fileList {
    list-style-type: none;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    text-align: left;
    align-items: left;
  }

  .warn {
    color: red;
    font-size: .8rem;
    margin: 5px 0;
  }
}

.fileSize {
  color: gray;
  font-size: .8rem;
  margin: 5px 0;
  font-weight: 300;
}

.clearUploaded {
  margin: 10px auto !important;
  align-self: center;
  justify-content: space-between;
  margin-top: 10px;
}

.clear {
  @include opacity-change;
  background: 0;
  outline: 0;
  font-size: 0.8rem;
  font-weight: 300;
  padding-left: 50px;
}