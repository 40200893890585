@import "styles/variables";

.popup {
  position: relative;
  background: white;
  width: 355px;
  min-height: 50px;
}

.close {
  text-align: right;
  cursor: pointer;
}
.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px;
}

.text {
  width: 100%;

  h5 {
    font-weight: 700;
    font-size: 0.8rem;
  }
  h6 {
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  p {
    color: grey;
    font-size: 0.7rem;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.distances {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 30px;
  padding: 5px;
  border-top: 1px solid #E2E2E2;
  .distanceInner {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      color: grey;
      margin: 0;
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 5px;
}

.button {
  width: 33%;
  margin: 0px 5px;
  color: white;
  background-color: #0B68E5;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.8rem;
  @include opacity-change;
  img {
    width: 48px;
    height: 48px;
  }
}
