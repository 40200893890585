.mainContainer {
  position: relative;
}

.boxUpgrade {
  position: absolute;
  top: 150px;
  left: 50%;
  padding-left: 20px;
  padding-right: 20px;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999; 
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 5px;

  &.blurBackground {
    filter: blur(4px);
    pointer-events: none;
  }
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;

  > div {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 4px;
    padding-bottom: 0;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
}
.cardTitle {
  font-size: 13px;
  color: #494949;
  font-weight: 700;
}

.cardValueContainer {
  display: flex;
}

.cardIcon {
  color: #494949;
}

.cardValue {
  margin-left: auto;
  font-size: 0.75rem;

  > span {
    font-size: 17px;
    font-weight: 700;
  }
}

.rotate90 {
  transform: rotate(90deg);
}

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chartHeader {
  display: flex;
}

.chartTitle {
  font-size: 17px;
  font-weight: 700;
  flex-grow: 1;
}