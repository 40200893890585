.settings-group {
  margin: 0 auto;
  margin-top: 15px;
  width: 95%;
  font-family: Poppins;

  * { font-family: Poppins; }

  h4 {
    font-weight: 500;
    padding-left: 5px;
  }
}

.settings-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  padding: 0px 10px;
}

.settings-title {
  font-weight: 400;
  margin-left: 5px; 
  font-size: 0.9rem;
}

.row { 
  display: flex; 
  align-items: center;
}