.button {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  gap: 5px;
  font-size: 14px;
  border-radius: 5px;
  background-color: rgb(228, 228, 228);
  transition: background-color 0.3s ease;
  color: #494949;
  justify-content: center;
}

.button:hover {
  background-color: #0B68E5;
  color: white;
}

.iconElement {
  height: 20px !important;
  width: 20px !important;
  
}