// Colors.
$primary: #3773B8;
$pane-color: #F8F6F2;

// Constants.
$responsive-size: 1300px;
$container-width: 1200px;
$mobile-width: 768px;
$min-width: 320px;
$min-font: 16px;
$max-font: 18px;

@use 'sass:math';
// Responsive font sizing.
@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin opacity-change {
  transition: opacity 0.3s;
  &:hover { opacity: 0.7; }
  &:active { opacity: 0.5; }
}