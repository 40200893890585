@import "styles/variables";

.layers-section {
  padding: 10px 0px;
  font-family: Poppins;

  * { font-family: Poppins; }

  h4 {
    font-weight: 400;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }
}

.layers-chunk {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 calc(2.5% + 5px);
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  text-align: left;
  transition: all 0.2s;
  background-color: $pane-color;

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    filter: brightness(0.9);
  }
  &:disabled {
    opacity: 1;
  }
}

.disabled {
  opacity: 0.5;
  &:hover {
    filter: brightness(1);
  }

  &:active {
    filter: brightness(1);
  }
}

.active {     
  filter: brightness(0.9);

  &:hover {
    filter: brightness(0.9);
  }
}

.image-section {
  width: 40%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 10px;

  div {
    margin-bottom: 5px;
  }
}

.divider {
  width: 90%;
  margin: 30px auto;
  height: 1px; 
  background-color: #A6A4A4;
}