@import "styles/variables";

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background: #FAFAFA;
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  width: 98px;
  transition: all 0.3s;
  color: #333;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-button {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 9;
  border-radius: 10px;
  padding-top: 5px;
}

.close-button {
  position: absolute;
  top: 0px;
  left: 95px;
  width: 30px;
  height: 30px;
  z-index: 10;
  background: transparent;
}

.logo {
  background: #F5F5F5;
  border-bottom: 3px solid #1553d3;
  width: 100%;
  height: 90px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-button {
  width: 100%;
  background: transparent;
  padding: 20px 0px;
  transition: background 0.1s;

  &:hover {
    background: rgba(0,0,0,0.05);
  }
}

.title {
  margin-top: 0px;
  font-weight: 500;
  font-size: 11px;
  color: #333;
}

.active {
  background: #DCDCDC;

  &:hover {
    background: #DCDCDC;
  }
}

.pane {
  position: absolute;
  top: 0;
  left: 98px;
  width: 22%;
  height: 100vh;
  min-width: 240px;
  background: $pane-color;
  z-index: 9;
  overflow-y: auto;

  h3 {
    text-align: center;
    margin: 15px auto;
  }
}

.account {
  width: 100%;
  overflow-x: hidden;
  word-wrap: break-word;
  * { font-family: Poppins; }
  border-bottom: 1px solid #eee;
  padding: 15px 0px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
}

.account-email {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 400;
}

.account-type {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 700;
  margin: 10px 0px;
}

.pro {
  background: #1553D3;
  padding: 3px 9px;
  border-radius: 4px;
  color: #f5f5f5;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
}

.basic {
  background: #bdbdbd;
  padding: 3px 9px;
  border-radius: 4px;
  color: #f5f5f5;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
}

.trial {
  background: #e89c22;
  padding: 3px 9px;
  border-radius: 4px;
  color: #f5f5f5;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
}

.link {
  font-size: 11px;
  margin: auto;
  margin-top: 10px;
  text-align: center;
  color: #1553d3;
  font-weight: 600;
  background: transparent;
  &:hover {
    opacity: 0.75;
  }
}

.version {
  padding-top: 15px;
}

.email {
  overflow-wrap: break-word;
}
.info {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  color: #555;
  font-weight: 400;
  font-size: 8px;
  text-align: center;
  padding: 15px 15px;
}

.menubutton {
  position: absolute;
  left: 12px;
  top: 15px;
  z-index: 9;
  background: transparent;
  padding: 0;
  display: flex;
}

@media screen and (max-width: 767px) {  
  .sidebar {
    left: -105px;
  }
}
