@import "styles/variables";

.container {
  width: 375px;
  position: absolute;
  top: 72px;
  left: calc(98px + 20px);
  z-index: 8;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 120px);
  overflow: hidden;
  color: #464646;

  * {
    font-family: Poppins;
  }
}

.header {
  position: relative;
  padding: 10px;
  .close {
    position: absolute;
    right: 12px;
    cursor: pointer;
  }
  .text {
    padding: 20px 10px 0;
    .type {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
    }
    h4 {
      font-weight: 700;
      font-size: 22px;
      line-height: 1.3;
      margin-top: 5px;
    }
    h5 {
      font-weight: 500;
      font-size: 14px;
      margin-top: 5px;
    }
    h6 {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.distances {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  padding: 8px 20px;
  border-top: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  .distanceInner {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-size: 13px;
      color: #555555;
      margin: 0;
    }
  }
}

.body {
  padding: 8px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;

  p {
    font-size: 14px;
    color: #333333;
    margin: 0;
  }
  .ext-link {
    display: block;
    color: #1553D3;
    overflow: hidden; 
    font-size: 14px;
    margin-top: 10px;
  }
  .action-link {
    display: block;
    color: #333333;
    font-size: 12px;
    font-weight: 600;
    padding: 12px 16px;
    background-color: #EBEBEB;
    border-radius: 20px;
    margin: 10px 0;
    width: fit-content;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px 20px;
  border-top: 1px solid #E2E2E2;
    
  .button {
    background-color: transparent;
    color: #333333;
    font-weight: 600;
    font-size: 11px;
    min-width: 80px;
    @include opacity-change;
    img {
      width: 48px;
      height: 48px;
    }
  }

}

.scontent {
  overflow-y: auto;
  max-height: calc(100vh - 400px);
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 375px;
    left: 20px;
  }
}