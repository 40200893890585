@import "styles/variables";

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
}

.label {
  margin-left: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
}

.regulation {
  margin: 20px 0;
  
  h4 {
    font-weight: 500;
    margin-left: 25px;
    font-size: 1rem;
    font-family: Poppins;
  }
}