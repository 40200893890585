@import "styles/variables";

.legend {
  padding: 10px;
  background: #FAFAFA;
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: absolute;
  z-index: 8;
  bottom: 20px;
  right: 20px;
  width: 520px;
}

.title {
  font-weight: 700;
  text-align: center;
  font-size: 1rem;
}

.legend-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 5px 10px;
   
  div {
    margin-top: 5px;
    text-align: center;
    font-size: 0.85rem;
    font-family: Poppins;
    line-height: 1.1rem;
  }
}

.rows {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}